ul.messenger {
  margin: 0;
  padding: 0;
}
ul.messenger > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.messenger.messenger-empty {
  display: none;
}
ul.messenger .messenger-message {
  overflow: hidden;
  *zoom: 1;
}
ul.messenger .messenger-message.messenger-hidden {
  display: none;
}
ul.messenger .messenger-message .messenger-phrase, ul.messenger .messenger-message .messenger-actions a {
  border: none;
  padding-right: 5px;
}
ul.messenger .messenger-message .messenger-actions {
  float: right;
}
ul.messenger .messenger-message .messenger-actions a {
  cursor: pointer;
  padding-bottom: 3px;
  text-decoration: none;
  padding-right: 12.5px;
}
ul.messenger .messenger-message ul, ul.messenger .messenger-message ol {
  margin: 10px 18px 0;
}
ul.messenger.messenger-fixed {
  position: fixed;
  z-index: 9999999999999999999999999999999;
}
ul.messenger.messenger-fixed .messenger-message {
  min-width: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul.messenger.messenger-fixed .message .messenger-actions {
  float: left;
}
ul.messenger.messenger-fixed.messenger-on-top {
  top: 20px;
}
ul.messenger.messenger-fixed.messenger-on-bottom {
  bottom: 20px;
}
ul.messenger.messenger-fixed.messenger-on-top, ul.messenger.messenger-fixed.messenger-on-bottom {
  left: 50%;
  width: 800px;
  margin-left: -400px;
}
@media (max-width: 960px) {
  ul.messenger.messenger-fixed.messenger-on-top, ul.messenger.messenger-fixed.messenger-on-bottom {
    left: 10%;
    width: 80%;
    margin-left: 0px;
  }
}
ul.messenger.messenger-fixed.messenger-on-top.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-bottom.messenger-on-right {
  right: 20px;
  left: auto;
}
ul.messenger.messenger-fixed.messenger-on-top.messenger-on-left, ul.messenger.messenger-fixed.messenger-on-bottom.messenger-on-left {
  left: 20px;
  margin-left: 0px;
}
ul.messenger.messenger-fixed.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-left {
  width: 350px;
}
ul.messenger.messenger-fixed.messenger-on-right .messenger-actions, ul.messenger.messenger-fixed.messenger-on-left .messenger-actions {
  float: left;
}
ul.messenger .messenger-spinner {
  display: none;
}
@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-right {
  0% {
    -moz-transform: rotate(0deg);
  }

  25% {
    -moz-transform: rotate(180deg);
  }

  50% {
    -moz-transform: rotate(180deg);
  }

  75% {
    -moz-transform: rotate(360deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-left {
  0% {
    -moz-transform: rotate(0deg);
  }

  25% {
    -moz-transform: rotate(0deg);
  }

  50% {
    -moz-transform: rotate(180deg);
  }

  75% {
    -moz-transform: rotate(180deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-right {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.messenger-spinner {
  position: relative;
  border-radius: 100%;
}
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-left;
  -moz-animation-name: ui-spinner-rotate-left;
  -ms-animation-name: ui-spinner-rotate-left;
  -o-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-right;
  -moz-animation-name: ui-spinner-rotate-right;
  -ms-animation-name: ui-spinner-rotate-right;
  -o-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
