
$SHPurple: #8B3765;
$SHGreen: #6B8C56;
$SHBeige: #E2E7DD;
$SHBeigeAccent: #E5DFCF;
$SHMediumGray: #666983;
$SHDarkGray: #26284B;
$SHNavyBlue: #26294A;
$SHOffWhite: #F8F6F3;

@include generateColors("shpurple", $SHPurple, white);
@include generateColors("shgreen", $SHGreen, white);
@include generateColors("shbeige", $SHBeige, $SHPurple);
@include generateColors("shbeigeaccent", $SHBeigeAccent,$SHPurple);
@include generateColors("shmediumgray", $SHMediumGray, white);
@include generateColors("shdarkgray", $SHDarkGray, white);
@include generateColors("shnavyblue", $SHNavyBlue, white);


@include generateColors("shpurpledark", darken($SHPurple, 10%), white);
@include generateColors("shgreendark", darken($SHGreen, 10%), white);
@include generateColors("shnavybluedark", darken($SHNavyBlue, 10%), white);
@include generateColors("shbeigedark", darken($SHBeige, 10%), $SHPurple);

@include generateColors("shpurplelight", lighten($SHPurple, 10%), white);
@include generateColors("shgreenlight", lighten($SHGreen, 10%), white);
@include generateColors("shnavybluelight", lighten($SHNavyBlue, 10%), white);
@include generateColors("shbeigelight", lighten($SHBeige, 10%), $SHPurple);