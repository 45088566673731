.left-tag, .right-tag {
  position: relative;
  display: inline-block;
  text-decoration: none;
  border: none !important;

  &:before {
    top: 0;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
  }
}

.left-tag {
  margin-left: 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 10px 0 8px !important;
  margin-bottom: $line-height-computed / 2;

  &:before {
    left: -12px;
    float: left;
    border-right-color: inherit;
    border-width: 12px 12px 12px 0;
  }
}

.right-tag {
  margin-right: 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 8px 0 10px !important;
  margin-bottom: $line-height-computed / 2;

  &:before {
    right: -12px;
    float: right;
    border-left-color: inherit;
    border-width: 12px 0 12px 12px;
  }
}

.terms {
  height: 200px;
  overflow: auto;
  background: #fff;
  padding: $line-height-computed;
}
