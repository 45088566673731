//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;

  > li,
  > rubix-breadcrumb-item,
  > rubix-breadcrumb-item > li {
    display: inline-block;

    + li:before,
    + rubix-breadcrumb-item:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "/\A0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      padding-left: 10px;
      color: $breadcrumb-color;
    }
  }

  > .active,
  > rubix-breadcrumb-item > .active {
    color: $breadcrumb-active-color;
  }
}
