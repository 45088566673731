/* ADD/REMOVE/CHANGE THEMES HERE */

@include theme-maker("default", (
  theme (
    bg #26284B
  ),
  header (
    logout (
      bg #8B3765,
      hover-bg lighten(#8B3765, 5%)
    ),
  ),
  body (
    bg #faf8f5
  ),
  logout (
    bg #8B3765,
    hover-bg #8B3765
  ),
  sidebar (
     avatar (
      bg lighten(#8B3765, 5%)
    ),
    header-container (
      bg #8B3765
    ),
    controls (
      bg #8B3765,
      btn (
        color #6B8C56,
        border darken(#8B3765, 2%),
        active-bg lighten(#8B3765, 5%),
        active-color lighten(#8B3765, 20%)
      )
    )
  )
));
