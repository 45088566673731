
@import './sass/main.scss';

body {
    margin: 0;
    padding: 0;
}

#content {
	 background: transparent;
}

body {
    -webkit-font-smoothing: subpixel-antialiased;
}