@mixin theme-form($list) {
  .form-control {
    border-color: get($list, components, form-control, border);

    &:focus {
      box-shadow: none !important;
      border-color: get($list, components, form-control, active, border);
    }
  }
}
