@import "bootstrap/common";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/bootstrap-forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/bootstrap-panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";

// Overrides
@import "bootstrap/forms";

// Custom components
@import "bootstrap/panels";
@import "bootstrap/components/tag";
@import "bootstrap/components/timeline";
@import "bootstrap/components/pricing-table";

// Base
@import "bootstrap/base";
@import "bootstrap/fonts";
@import "bootstrap/layout/col";
@import "bootstrap/layout/grid";

@import "overrides";

body.flash {
  display: none;
}

// override bootstrap-slider styles
.slider-track,
.slider-handle,
.slider-selection,
.slider.slider-disabled .slider-track,
.slider.slider-disabled .slider-handle {
  filter: none;
  box-shadow: none;
  border-radius: 0;
  background-image: none;
  background-repeat: none;
}

.slider-selection {
  box-sizing: border-box;
  border-radius: 4px;
}

.slider-track {
  background: #f0f0f0;
}

.slider-handle {
  background: #3a94a5;
}

.slider.slider-disabled .slider-track {
  background: #e5e5e5;
}

// override bootstrap-datetimepicker styles
.bootstrap-datetimepicker-widget a[data-action] {
  border: none;
  display: inline;
}

.bootstrap-datetimepicker-widget button[data-action] {
  padding: 2px 15px;
}

.bootstrap-datetimepicker-widget td {
  a[data-action="togglePicker"] {
    border: 0;
    display: block;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px !important;
  }

  a[data-action="togglePicker"] span {
    margin: 0;
    padding: 5px;
    font-size: 180%;
    background: none;
    width: 54px !important;
    height: auto !important;
    line-height: 1 !important;
  }
}

.bootstrap-datetimepicker-widget th.picker-switch {
  background: #eeeeee;
}

.bootstrap-datetimepicker-widget .picker-switch.accordion-toggle {
  background: #f5f5f5 !important;
}

.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
}

.datetimepicker-inline {
  width: 100%;
  padding: 25px;
  display: table;
}

html[dir='rtl'] {
  [class^="icon-"].float-right-rtl,
  [class*=" icon-"].float-right-rtl {
    position: relative;
    /*@noflip*/float: right;
  }
}

body {
  opacity: 1;
  transition: all 0.7s ease;

  &.fade-out {
    transition: all 0.25s ease;
    opacity: 0;
  }
}

#app-preloader > .preloader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: #E9EDF2;
  z-index: 99999999999;
}
#app-preloader > .preloader > img {
  margin: auto;
  display: block;
  margin-top: 150px;
  position: relative;
}
