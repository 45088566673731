code[class*="language-"], pre[class*="language-"] {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-size: 15px;
  line-height: 1.5;
  color: #dccf8f;
  text-shadow: 0;
  margin-bottom: $line-height-computed;
}

pre[class*="language-"], :not(pre) > code[class*="language-"] {
  border-radius: 5px;
  border: 1px solid #000;
  color: #DCCF8F;
  background: #181914;

}

pre[class*="language-"] {
  padding: 12px; }

:not(pre) > code[class*="language-"] {
  padding: 2px 6px; }

code[class*="language-"] .namespace, pre[class*="language-"] .namespace {
  opacity: .7; }
code[class*="language-"] .token.comment, code[class*="language-"] .token.prolog, code[class*="language-"] .token.doctype, code[class*="language-"] .token.cdata, pre[class*="language-"] .token.comment, pre[class*="language-"] .token.prolog, pre[class*="language-"] .token.doctype, pre[class*="language-"] .token.cdata {
  color: #586e75;
  font-style: italic;
}
code[class*="language-"] .token.null, code[class*="language-"] .token.operator, code[class*="language-"] .token.boolean, pre[class*="language-"] .token.null, pre[class*="language-"] .token.operator, pre[class*="language-"] .token.boolean {
  color: #dccf8f; }
code[class*="language-"] .token.number,
 code[class*="language-"] .token.string, pre[class*="language-"] .token.string, pre[class*="language-"] .token.number  {
  color: #468966;
}

code[class*="language-"] .token.attr-name,
pre[class*="language-"] .token.attr-name {
   color: #b89859;
 }
code[class*="language-"] .token.entity, code[class*="language-"] .token.url, .language-css code[class*="language-"] .token.string, .style code[class*="language-"] .token.string, pre[class*="language-"] .token.entity, pre[class*="language-"] .token.url, .language-css pre[class*="language-"] .token.string, .style pre[class*="language-"] .token.string {
  color: #dccf8f; }
code[class*="language-"] .token.selector, pre[class*="language-"] .token.selector {
  color: #859900; }
code[class*="language-"] .token.atrule, code[class*="language-"] code[class*="language-"] .token.keyword, code[class*="language-"] .token.control, code[class*="language-"] .token.directive, code[class*="language-"] .token.important, code[class*="language-"] .token.unit, pre[class*="language-"] .token.atrule, pre[class*="language-"] .token.keyword, pre[class*="language-"] .token.control, pre[class*="language-"] .token.directive, pre[class*="language-"] .token.important, pre[class*="language-"] .token.unit {
  color: #cb4b16;
 }

.token.attr-value,
pre[class*="language-"] .token.attr-value {
  color:  #468966;
}
code[class*="language-"] .token.regex, code[class*="language-"] .token.statement, pre[class*="language-"] .token.regex, pre[class*="language-"] .token.statement {
   }
code[class*="language-"] .token.placeholder, code[class*="language-"] .token.variable, pre[class*="language-"] .token.placeholder, pre[class*="language-"] .token.variable {
  color:  #b58900;  }
.token.property, .token.tag, .token.boolean, .token.number, code[class*="language-"] .token.property, pre[class*="language-"] .token.property {
   color: #b89859;
}
code[class*="language-"] .token.tag, pre[class*="language-"] .token.tag {
  color: #ffb03b; }
code[class*="language-"] .token.important, code[class*="language-"] .token.statement, pre[class*="language-"] .token.important, pre[class*="language-"] .token.statement {
  /* font-weight: bold; */
  color: #dc322f;
}
code[class*="language-"] .token.punctuation, pre[class*="language-"] .token.punctuation {
  color:  #dccf8f; }
code[class*="language-"] .token.entity, pre[class*="language-"] .token.entity {
  cursor: help; }
code[class*="language-"] .token.debug, pre[class*="language-"] .token.debug {
  color: red; }

.pojoaque-colors {
  color: #586e75;
  color: #b64926;
  color: #468966;
  color: #ffb03b;
  color: #b58900;
  color: #b89859;
  color: #dccf8f;
  color: #d3a60c;
  color: #cb4b16;
  color: #dc322f;
  color: #073642;
  color: #181914;
}
