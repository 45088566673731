@keyframes vex-flipin-horizontal {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }
}

@-webkit-keyframes vex-flipin-horizontal {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }
}

@-moz-keyframes vex-flipin-horizontal {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }
}

@-ms-keyframes vex-flipin-horizontal {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }
}

@-o-keyframes vex-flipin-horizontal {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }
}

@keyframes vex-flipout-horizontal {
  0% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }

  100% {
    opacity: 0;
    transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
  }
}

@-webkit-keyframes vex-flipout-horizontal {
  0% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }

  100% {
    opacity: 0;
    transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
  }
}

@-moz-keyframes vex-flipout-horizontal {
  0% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }

  100% {
    opacity: 0;
    transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
  }
}

@-ms-keyframes vex-flipout-horizontal {
  0% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }

  100% {
    opacity: 0;
    transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
  }
}

@-o-keyframes vex-flipout-horizontal {
  0% {
    opacity: 1;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }

  100% {
    opacity: 0;
    transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
  }
}

.vex.vex-theme-flat-attack {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  -ms-perspective: 1300px;
  -o-perspective: 1300px;
  perspective: 1300px;
  -webkit-perspective-origin: 50% 150px;
  -moz-perspective-origin: 50% 150px;
  -ms-perspective-origin: 50% 150px;
  -o-perspective-origin: 50% 150px;
  perspective-origin: 50% 150px;
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 1.5em;
}
.vex.vex-theme-flat-attack.vex-closing .vex-content {
  animation: vex-flipout-horizontal 0.5s;
  -webkit-animation: vex-flipout-horizontal 0.5s;
  -moz-animation: vex-flipout-horizontal 0.5s;
  -ms-animation: vex-flipout-horizontal 0.5s;
  -o-animation: vex-flipout-horizontal 0.5s;
  -webkit-backface-visibility: hidden;
}
.vex.vex-theme-flat-attack .vex-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  animation: vex-flipin-horizontal 0.5s;
  -webkit-animation: vex-flipin-horizontal 0.5s;
  -moz-animation: vex-flipin-horizontal 0.5s;
  -ms-animation: vex-flipin-horizontal 0.5s;
  -o-animation: vex-flipin-horizontal 0.5s;
  -webkit-backface-visibility: hidden;
}
.vex.vex-theme-flat-attack .vex-content {
  font-family: Lato, sans-serif;
  font-weight: 200;
  background: white;
  color: #444444;
  padding: 2em 2em 3em 2em;
  line-height: 1.5em;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 600px;
}
.vex.vex-theme-flat-attack .vex-content h1, .vex.vex-theme-flat-attack .vex-content h2, .vex.vex-theme-flat-attack .vex-content h3, .vex.vex-theme-flat-attack .vex-content h4, .vex.vex-theme-flat-attack .vex-content h5, .vex.vex-theme-flat-attack .vex-content h6, .vex.vex-theme-flat-attack .vex-content p, .vex.vex-theme-flat-attack .vex-content ul, .vex.vex-theme-flat-attack .vex-content li {
  color: inherit;
}
.vex.vex-theme-flat-attack .vex-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.vex.vex-theme-flat-attack .vex-close:before {
  font-family: Lato, sans-serif;
  font-weight: 100;
  line-height: 1px;
  padding-top: 0.5em;
  display: block;
  font-size: 2em;
  text-indent: 1px;
  overflow: hidden;
  height: 1.25em;
  width: 1.25em;
  text-align: center;
  top: 0;
  right: 0;
  color: white;
  background: #666666;
}
.vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-message {
  margin-bottom: 0.5em;
}
.vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input {
  margin-bottom: 0.5em;
}
.vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input textarea, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="date"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="datetime"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="datetime-local"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="email"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="month"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="number"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="password"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="search"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="tel"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="text"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="time"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="url"], .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="week"] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: #f0f0f0;
  width: 100%;
  padding: 0.25em 0.67em;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  min-height: 2.5em;
  margin: 0 0 0.25em;
}
.vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px #666666;
  -moz-box-shadow: inset 0 0 0 2px #666666;
  box-shadow: inset 0 0 0 2px #666666;
  outline: none;
}
.vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-buttons {
  *zoom: 1;
  padding-top: 1em;
  margin-bottom: -3em;
  margin-left: -2em;
  margin-right: -2em;
}
.vex.vex-theme-flat-attack .vex-dialog-form .vex-dialog-buttons:after {
  content: "";
  display: table;
  clear: both;
}
.vex.vex-theme-flat-attack .vex-dialog-button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border: 0;
  margin: 0;
  float: right;
  padding: 0.5em 1em;
  font-size: 1.13em;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.1em;
  line-height: 1em;
  font-family: inherit;
}
.vex.vex-theme-flat-attack .vex-dialog-button.vex-last {
  margin-left: 0;
}
.vex.vex-theme-flat-attack .vex-dialog-button:focus {
  outline: none;
}
.vex.vex-theme-flat-attack .vex-dialog-button.vex-dialog-button-primary {
  background: #666666;
  color: white;
}
.vex.vex-theme-flat-attack .vex-dialog-button.vex-dialog-button-primary:focus {
  -webkit-box-shadow: inset 0 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 3px rgba(0, 0, 0, 0.2);
}
.vex.vex-theme-flat-attack .vex-dialog-button.vex-dialog-button-secondary {
  background: white;
  color: #cccccc;
}
.vex.vex-theme-flat-attack .vex-dialog-button.vex-dialog-button-secondary:focus {
  -webkit-box-shadow: inset 0 3px #aaaaaa;
  -moz-box-shadow: inset 0 3px #aaaaaa;
  box-shadow: inset 0 3px #aaaaaa;
  background: #eeeeee;
  color: #777777;
}
.vex.vex-theme-flat-attack .vex-dialog-button.vex-dialog-button-secondary:hover, .vex.vex-theme-flat-attack .vex-dialog-button.vex-dialog-button-secondary:active {
  color: #777777;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-close:before {
  background: #ff7ea7;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px #ff7ea7;
  -moz-box-shadow: inset 0 0 0 2px #ff7ea7;
  box-shadow: inset 0 0 0 2px #ff7ea7;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-pink .vex-dialog-form .vex-dialog-buttons .vex-dialog-button.vex-dialog-button-primary {
  background: #ff7ea7;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-close:before {
  background: #ce4a55;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px #ce4a55;
  -moz-box-shadow: inset 0 0 0 2px #ce4a55;
  box-shadow: inset 0 0 0 2px #ce4a55;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-red .vex-dialog-form .vex-dialog-buttons .vex-dialog-button.vex-dialog-button-primary {
  background: #ce4a55;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-close:before {
  background: #34b989;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px #34b989;
  -moz-box-shadow: inset 0 0 0 2px #34b989;
  box-shadow: inset 0 0 0 2px #34b989;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-green .vex-dialog-form .vex-dialog-buttons .vex-dialog-button.vex-dialog-button-primary {
  background: #34b989;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-close:before {
  background: #477fa5;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px #477fa5;
  -moz-box-shadow: inset 0 0 0 2px #477fa5;
  box-shadow: inset 0 0 0 2px #477fa5;
}
.vex.vex-theme-flat-attack.vex-theme-flat-attack-blue .vex-dialog-form .vex-dialog-buttons .vex-dialog-button.vex-dialog-button-primary {
  background: #477fa5;
}

.vex-loading-spinner.vex-theme-flat-attack {
  height: 4em;
  width: 4em;
}
